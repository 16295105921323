import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { encode as base64_encode, decode as base64_decode } from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";

const UpdateCategory = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const [seats, setSeats] = useState("")
  const [minimumPrice, setMinimumPrice] = useState("")
  const admin_id = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [photo, setPhoto] = useState("")

  if (!user_id || role !== "admin") {
    history.push("/")
  }

  document.addEventListener("DOMContentLoaded", function () {
    history.push(location.pathname)
  })

  const category = useParams()

  const getApiData = () => {
    fetch(`${BASE_URL}get-single-category/${category.category_id}?app_id=${APP_ID}&key=${APP_KEY}`)
      .then(res => res.json())
      .then(data => {
        setIsLoading(false)
        setName(data.data.name)
        setSeats(data.data.seats)
        setPrice(data.data.price_per_km)
        setMinimumPrice(data.data.minimum_price)
      })
  }


  useEffect(() => {
    setIsLoading(true)
    getApiData()
  }, [category.category_id])

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return base64_decode(category.category_name);
  };

  const updateCategoryFunction = (event) => {
    event.preventDefault()
    setLoading(true)
    if (photo) {
      const formData = new FormData();
      formData.append("category_id", category.category_id)
      formData.append("name", base64_encode(name))
      formData.append("seats", base64_encode(seats))
      formData.append("price", base64_encode(price))
      formData.append("minimumPrice", base64_encode(minimumPrice))
      formData.append("photo", photo)
      formData.append("updated_by", base64_encode(admin_id))
      fetch(`${BASE_URL}update-category?app_id=${APP_ID}&key=${APP_KEY}`, { method: "PUT", body: formData })
        .then(res => res.json())
        .then(data => {
          if (data.result === "SUCCESS") {
            setLoading(false)
            getApiData()
            swal({
              title: data.result,
              text: data.message,
              icon: "success",
              button: "Okay",
            });
          } else {
            setLoading(false)
            swal({
              title: data.result,
              text: data.message,
              icon: "warning",
              button: "Okay",
            });
          }
        })
        .catch((err) => {
          setLoading(false)
          swal({
            title: "Network Request Error",
            text: err.message,
            icon: "warning",
            button: "Okay",
          });
        })
    } else {
      const formData = new FormData();
      formData.append("category_id", category.category_id)
      formData.append("name", base64_encode(name))
      formData.append("seats", base64_encode(seats))
      formData.append("price", base64_encode(price))
      formData.append("minimumPrice", base64_encode(minimumPrice))
      formData.append("updated_by", base64_encode(admin_id))
      fetch(`${BASE_URL}update-category?app_id=${APP_ID}&key=${APP_KEY}`, { method: "PUT", body: formData })
        .then(res => res.json())
        .then(data => {
          if (data.result === "SUCCESS") {
            setLoading(false)
            getApiData()
            swal({
              title: data.result,
              text: data.message,
              icon: "success",
              button: "Okay",
            });
          } else {
            setLoading(false)
            swal({
              title: data.result,
              text: data.message,
              icon: "warning",
              button: "Okay",
            });
          }
        })
        .catch((err) => {
          setLoading(false)
          swal({
            title: "Network Request Error",
            text: err.message,
            icon: "warning",
            button: "Okay",
          });
        })
    }
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <>{isLoading === true ? (<Indicator />) : (
          <>
            <PlainHeader />
            <CardBody>
              <Card>
                <CardBody>
                  <Form autoComplete="off" onSubmit={(event) => updateCategoryFunction(event)}>
                    <h6 className="heading-small text-muted mb-4">
                      <Button
                        color="primary"
                        // href="#pablo"
                        onClick={() => history.goBack()}
                        size="sm"
                      >
                        <i className="fa fa-angle-double-left"></i> back
                      </Button> {base64_decode(category.category_name)}
                    </h6>
                    <div className="pl-lg">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-category-name"
                            >
                              Category name
                            </label>
                            <Input
                              className="form-control"
                              id="input-category-name"
                              placeholder="Category name"
                              autoFocus
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-category-seats"
                            >
                              Number of Seats
                            </label>
                            <Input
                              className="form-control"
                              id="input-category-seats"
                              placeholder="Number of Seats"
                              autoFocus
                              type="number"
                              value={seats}
                              onChange={(e) => setSeats(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-category-price"
                            >
                              Price per KM
                            </label>
                            <Input
                              className="form-control"
                              id="input-category-price"
                              placeholder="Price per KM"
                              type="text"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-minimum-price"
                            >
                              Minimum price
                            </label>
                            <Input
                              className="form-control"
                              id="input-minimum-price"
                              placeholder="Minimum price"
                              type="text"
                              value={minimumPrice}
                              onChange={(e) => setMinimumPrice(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-category-image"
                            >
                              Category Image
                            </label>
                            <Input
                              className="form-control"
                              id="input-category-Image"
                              placeholder="Category Image"
                              type="file"
                              // defaultValue={photo}
                              onChange={(e) => setPhoto(e.target.files[0])}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {loading === true ? (
                        <Loader />
                      ) : (
                        <Button color="primary">
                          Update
                        </Button>
                      )}
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </CardBody>
          </>
        )}</>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default UpdateCategory;
