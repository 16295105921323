import {
    Card,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    Row,
    CardBody,
    Modal,
    Form,
    Col,
    FormGroup,
    Input,
    Button,
    CardHeader
} from "reactstrap";
import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { BASE_URL } from "Api/Api";
import Indicator from "components/Loader/Indicator";
import swal from "sweetalert";
import { encode as base64_encode } from 'base-64';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Loader from "components/Loader/Loader";

const Categories = () => {
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(false)
    const [submitloading, setSubmitLoading] = useState(false)
    const history = useHistory()
    const user_id = localStorage.getItem("user_id")
    const role = localStorage.getItem("role")
    const [name, setName] = useState("")
    const [price, setPrice] = useState("")
    const [seats, setSeats] = useState("")
    const [minimumPrice, setMinimumPrice] = useState("")
    const [photo, setPhoto] = useState("")
    const admin_id = localStorage.getItem('user_id')
    const [registerModal, setRegisterModal] = useState(false)

    if (!user_id || role !== "admin") {
        history.push("/")
    }

    const getApiData = () => {
        fetch(`${BASE_URL}get-all-categories?app_id=${APP_ID}&key=${APP_KEY}`)
            .then(res => res.json())
            .then(data => {
                setLoading(false)
                setCourses(data.data)
                $(document).ready(function () {
                    $('#myTable').DataTable();
                });
            })
    }

    useEffect(() => {
        setLoading(true)
        getApiData();
    }, [])

    const registerCourse = (event) => {
        event.preventDefault()
        setSubmitLoading(true)
        const formData = new FormData();
        if (photo) {
            formData.append("name", base64_encode(name))
            formData.append("price", base64_encode(price))
            formData.append("seats", base64_encode(seats))
            formData.append("minimumPrice", base64_encode(minimumPrice))
            formData.append("photo", photo)
            formData.append("updated_by", base64_encode(admin_id))
            formData.append("registed_by", base64_encode(admin_id))
            fetch(`${BASE_URL}register-category?app_id=${APP_ID}&key=${APP_KEY}`, { method: "POST", body: formData })
                .then(res => res.json())
                .then(data => {
                    getApiData()
                    if (data.result === "SUCCESS") {
                        setSubmitLoading(false)
                        setRegisterModal(false)
                        event.target.reset()
                        swal({
                            title: data.result,
                            text: data.message,
                            icon: "success",
                            button: "Okay",
                        });
                    } else {
                        setSubmitLoading(false)
                        swal({
                            title: data.result,
                            text: data.message,
                            icon: "warning",
                            button: "Okay",
                        });
                    }
                })
                .catch((err) => {
                    setSubmitLoading(false)
                    swal({
                        title: "Network Request Error",
                        text: err.message,
                        icon: "warning",
                        button: "Okay",
                    });
                })
        } else {
            formData.append("name", base64_encode(name))
            formData.append("price", base64_encode(price))
            formData.append("seats", base64_encode(seats))
            formData.append("minimumPrice", base64_encode(minimumPrice))
            formData.append("updated_by", base64_encode(admin_id))
            formData.append("registed_by", base64_encode(admin_id))
            fetch(`${BASE_URL}register-category?app_id=${APP_ID}&key=${APP_KEY}`, { method: "POST", body: formData })
                .then(res => res.json())
                .then(data => {
                    getApiData()
                    if (data.result === "SUCCESS") {
                        setSubmitLoading(false)
                        setRegisterModal(false)
                        event.target.reset()
                        swal({
                            title: data.result,
                            text: data.message,
                            icon: "success",
                            button: "Okay",
                        });
                    } else {
                        setSubmitLoading(false)
                        swal({
                            title: data.result,
                            text: data.message,
                            icon: "warning",
                            button: "Okay",
                        });
                    }
                })
                .catch((err) => {
                    setSubmitLoading(false)
                    swal({
                        title: "Network Request Error",
                        text: err.message,
                        icon: "warning",
                        button: "Okay",
                    });
                })
        }
    }

    const deleteCourse = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this category!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(`${BASE_URL}delete-category/${base64_encode(id.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
                        .then(del => del.json())
                        .then(del => {
                            swal(`${del.result}! ${del.message}!`, {
                                icon: "success",
                            });
                            getApiData()
                        })
                } else {
                    swal("Your data is safe!");
                }
            });
    }

    document.addEventListener("keyup", (e) => {
        if (e.key === "Escape") {
            setRegisterModal(false)
        }
    })

    return (
        <>{loading === true ? (<Indicator />) : (
            <>
                {/* Page content */}
                <PlainHeader />
                <CardBody>
                    <Modal isOpen={registerModal}>
                        <Card>
                            <CardBody>
                                <div style={{ alignItems: "flex-end", textAlign: "right" }}>
                                    <button className="btn btn-info" onClick={() => setRegisterModal(false)}>close</button>
                                </div>
                                <Form autoComplete="off" id="category-form" onSubmit={(event) => registerCourse(event)}>
                                    <h6 className="heading-small text-muted mb-4">
                                        Register Category
                                    </h6>
                                    <div className="pl-lg">
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-course-name"
                                                    >
                                                        Category name
                                                    </label>
                                                    <Input
                                                        className="form-control"
                                                        id="input-course-name"
                                                        placeholder="Category name"
                                                        autoFocus
                                                        type="text"
                                                        // value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-course-seats"
                                                    >
                                                        Number of Seats
                                                    </label>
                                                    <Input
                                                        className="form-control"
                                                        id="input-course-seats"
                                                        placeholder="Number of Seats"
                                                        autoFocus
                                                        type="number"
                                                        // value={seats}
                                                        onChange={(e) => setSeats(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-price-per-km"
                                                    >
                                                        Price per KM
                                                    </label>
                                                    <Input
                                                        className="form-control"
                                                        id="input-price-per-km"
                                                        placeholder="Price per KM"
                                                        type="number"
                                                        min={1}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-minimum-price"
                                                    >
                                                        Minimum price
                                                    </label>
                                                    <Input
                                                        className="form-control"
                                                        id="input-minimum-price"
                                                        placeholder="Minimum price"
                                                        type="number"
                                                        min={1}
                                                        onChange={(e) => setMinimumPrice(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-course-image"
                                                    >
                                                        Category Image
                                                    </label>
                                                    <Input
                                                        className="form-control"
                                                        id="input-course-Image"
                                                        placeholder="Category Image"
                                                        type="file"
                                                        // defaultValue={photo}
                                                        onChange={(e) => setPhoto(e.target.files[0])}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {submitloading === true ? (
                                            <Loader />
                                        ) : (
                                            <Button color="primary">
                                                Register
                                            </Button>
                                        )}
                                    </div>
                                    <hr className="my-4" />
                                </Form>
                            </CardBody>
                        </Card>
                    </Modal>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Categories</h3>
                                </CardHeader>
                                <CardBody>
                                    <Button onClick={() => setRegisterModal(true)} color="primary">Add Category</Button>
                                    <hr />
                                    <Table id="myTable" className="align-items-center table-flush" responsive>
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">S/N</th>
                                                <th scope="col">NAME</th>
                                                <th scope="col">SEATS</th>
                                                <th scope="col">MINIMUM PRICE</th>
                                                <th scope="col">PRICE PER KM</th>
                                                <th scope="col">REGISTED BY</th>
                                                <th scope="col">CREATED AT</th>
                                                <th scope="col">UPDATED BY</th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {courses.map((data, idx) =>
                                                <tr key={idx}>
                                                    <td>{++idx}</td>
                                                    <td>
                                                        <img src={data.photo} style={{ width: 80, height: 70, borderRadius: 6 }} alt="..." /> {data.name}
                                                    </td>
                                                    <td>{data.seats}</td>
                                                    <td>Tsh: {Intl.NumberFormat().format(data.minimum_price)}/=</td>
                                                    <td>Tsh: {Intl.NumberFormat().format(data.price_per_km)}/=</td>
                                                    <td>{data.registed_by}</td>
                                                    <td>{data.created_at}</td>
                                                    <td>{data.updated_by}</td>
                                                    <td className="text-right">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                className="btn-icon-only text-dark"
                                                                href="#pablo"
                                                                role="button"
                                                                size="sm"
                                                                color=""
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <i className="fas fa-ellipsis-v" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                                <DropdownItem
                                                                    href="#pablo"
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        deleteCourse(data.id)
                                                                    }}
                                                                >
                                                                    Delete
                                                                </DropdownItem>

                                                                <DropdownItem
                                                                    to={`/update-category/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                                                    tag={Link}
                                                                >
                                                                    Update
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </CardBody>
            </>
        )}</>
    );
};

export default Categories;
