import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import { encode as base64_encode } from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function AppVersion() {
  const [version, setVersion] = useState("")
  const admin_id = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [feesData, setFeesData] = useState([])

  if (!user_id || role !== "admin") {
    history.push("/")
  }

  const getApiData = () => {
    const url = `${BASE_URL}get-app-version?app_id=${APP_ID}&key=${APP_KEY}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setFeesData(data)
      })
  }

  useEffect(() => {
    getApiData()
  }, [])

  const registerFees = (event) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData();
    formData.append("version", base64_encode(version))
    formData.append("updated_by", base64_encode(admin_id))
    formData.append("registed_by", base64_encode(admin_id))
    fetch(`${BASE_URL}register-app-version?app_id=${APP_ID}&key=${APP_KEY}`, { method: "POST", body: formData })
      .then(res => res.json())
      .then(data => {
        getApiData()
        if (data.result === "SUCCESS") {
          setLoading(false)
          event.target.reset()
          swal({
            title: data.result,
            text: data.message,
            icon: "success",
            button: "Okay",
          });
        } else {
          setLoading(false)
          swal({
            title: data.result,
            text: data.message,
            icon: "warning",
            button: "Okay",
          });
        }
      })
      .catch((err) => {
        setLoading(false)
        swal({
          title: "Network Request Error",
          text: err.message,
          icon: "warning",
          button: "Okay",
        });
      })
  }

  return (
    <>
      <PlainHeader />
      <CardBody>
        <Card>
          <CardBody>
            <Form autoComplete="off" id="category-form" onSubmit={(event) => registerFees(event)}>
              <h6 className="heading-small text-muted mb-4">
                Set Driver Activation Fees
              </h6>
              <div className="pl-lg">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-course-version"
                      >
                        Activation Fees
                      </label>
                      <Input
                        className="form-control"
                        id="input-course-version"
                        placeholder="Activation Fees"
                        autoFocus
                        type="text"
                        min={1000}
                        // value={version}
                        onChange={(e) => setVersion(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {loading === true ? (
                  <Loader />
                ) : (
                  <Button color="primary">
                    Register
                  </Button>
                )}
              </div>
              <hr className="my-4" />

              <Table id="" className="align-items-center table-flush" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">VERSION</th>
                    <th scope="col">REGISTED BY</th>
                    <th scope="col">CREATED AT</th>
                    <th scope="col">UPDATED BY</th>
                    <th scope="col">UPDATED AT</th>
                  </tr>
                </thead>
                <tbody>
                  {feesData.map((data, idx) =>
                    <tr key={idx}>
                      <td>{data.version}</td>
                      <td>{data.registed_by}</td>
                      <td>{data.created_at}</td>
                      <td>{data.updated_by}</td>
                      <td>{data.updated_at}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Form>
          </CardBody>
        </Card>
      </CardBody>
    </>
  )
}